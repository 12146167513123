import "./utilities/ready"
import LazyLoad from "vanilla-lazyload"
import "./components/Menubar"
import "./components/MenubarItem"
import "./components/MenuItem"
import "./components/PopupMenu"
import {TreeLinks} from "./components/TreeLinks"
import { MobileMenu } from "./components/MobileMenu"
import { gsap } from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { smoothScrollAnchor } from "smooth-scroll-anchor";
import anchorScroll from "anchor-scroll";

anchorScroll.init({
  updateUrl: true,
  align: 'middle',
  offset: 0,
  ease: 'out-quad',
  duration: 1500,
  selector: '.smooth-scroll' // default a[href*='#']
});

const lazyLoadInstance = new LazyLoad({
  elements_selector: ".lazy"
});

ready(function () {
  // Activate Menu Bars WCAG functionalty
  const menus = document.querySelectorAll("[role='menubar']")
  if(menus.length > 0){
    menus.forEach((i) => {
      new Menubar(i).init();      
    })
  }

  // Activate Tree WCAG functionalty
  var trees = document.querySelectorAll('[role="tree"]');
  for (var i = 0; i < trees.length; i++) {
    var t = new TreeLinks(trees[i]);
    t.init();
  }

  MobileMenu()

  var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  if (viewportWidth > 1024) {
    gsap.registerPlugin(CSSRulePlugin, DrawSVGPlugin);
    gsap.from("#prefix__AnimationWrapper path", {duration:8, stagger: 0.01, drawSVG: 0});
    console.log('Wide viewport');
  } else {
    console.log('Small viewport');
  }

});


